<template>
  <div>
    <base-page
      title="Reference units"
      :breadcrumbs="breadcrumbs"
      call-to-action-text="Add reference unit"
      call-to-action-permission="insurance.reference-units.store"
      @call-to-action="dialog = true"
    >
      <template #content>
        <reference-unit-list
          @edit="edit"
        />
      </template>
    </base-page>

    <reference-unit-form
      v-if="dialog"
      :unit="unit"
      :dialog="dialog"
      @close="close()"
    />
  </div>
</template>

<script>
export default {
  components: {
    'reference-unit-list': () => import('@/views/insurance/ReferenceUnitList.vue'),
    'reference-unit-form': () => import('@/views/insurance/ReferenceUnitForm.vue'),
  },

  data () {
    return {
      unit: null,
      dialog: false,
      breadcrumbs: [
        { text: 'Jurisdictions', disabled: true, },
      ],
    }
  },

  methods: {
    close () {
      this.dialog = false
    },

    edit (unit) {
      this.unit = unit
      this.dialog = true
    }
  }
} 
</script>